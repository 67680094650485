import {
  AskRequest,
  AskResponse,
  ChatRequest,
  FeedbackRequest,
  LoginRequest,
  LoginResponse
} from './models';
import { v4 as uuidv4 } from 'uuid';

import auth, { resetAuth, setAuth } from '../proxies/auth';
import conversation from '../proxies/conversation';
import { snapshot } from 'valtio/vanilla';

export async function askApi(options: AskRequest): Promise<AskResponse> {
  const response = await fetch('/ask', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      question: options.question,
      approach: options.approach,
      overrides: {
        retrieval_mode: options.overrides?.retrievalMode,
        semantic_ranker: options.overrides?.semanticRanker,
        semantic_captions: options.overrides?.semanticCaptions,
        top: options.overrides?.top,
        temperature: options.overrides?.temperature,
        prompt_template: options.overrides?.promptTemplate,
        prompt_template_prefix: options.overrides?.promptTemplatePrefix,
        prompt_template_suffix: options.overrides?.promptTemplateSuffix,
        exclude_category: options.overrides?.excludeCategory
      }
    })
  });

  const parsedResponse: AskResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || 'Unknown error');
  }
  conversation.chat_sequence += 1;
  return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
  const { id_token, token, name, email, org } = snapshot(auth);
  const { conversation_id, chat_id, chat_sequence } = snapshot(conversation);
  const log_chat_id =
    conversation.chat_id === null ? uuidv4() : conversation.chat_id;
  conversation.chat_id = conversation.chat_id === null ? log_chat_id : chat_id;
  console.log('CHATAPI', conversation_id, log_chat_id, chat_sequence);

  // check which token will be used
  const tokenTobeSubmitted = token !== null ? token : id_token;

  const logging_info = {
    conversation_id: conversation_id,
    chat_id: log_chat_id,
    chat_sequence: conversation.chat_sequence,
    user_fullname: name,
    user_email: email,
    user_organization: org
  };

  const response = await fetch('/chat', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      logging_info,
      history: options.history,
      approach: options.approach,
      overrides: {
        retrieval_mode: options.overrides?.retrievalMode,
        semantic_ranker: options.overrides?.semanticRanker,
        semantic_captions: options.overrides?.semanticCaptions,
        top: options.overrides?.top,
        temperature: options.overrides?.temperature,
        prompt_template: options.overrides?.promptTemplate,
        prompt_template_prefix: options.overrides?.promptTemplatePrefix,
        prompt_template_suffix: options.overrides?.promptTemplateSuffix,
        exclude_category: options.overrides?.excludeCategory,
        suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
        scoring_profile: options.overrides?.scoringProfile,
        organization: options.overrides?.organization
      }
    })
  });

  const parsedResponse: AskResponse = await response.json();
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || 'Unknown error');
  }
  conversation.chat_sequence += 1;
  return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
  return `/content/${citation}`;
}

export async function postBasicAuth(
  options: LoginRequest
): Promise<LoginResponse> {
  const response = await fetch('/basic_auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: options.username,
      password: options.password
    })
  });

  if (!response.ok) {
    throw new Error('Authentication failed');
  }

  const data = await response.json();
  const { token } = data;

  auth.token = token;

  return {
    email: `${options.username}@example.com`,
    name: 'Admin User',
    org: 'UN'
  };
}

export const getMe = async () => {
  const response = await fetch('/me');

  if (!response.ok) {
    // Se la risposta non è OK, resetta l'autenticazione
    resetAuth();
    throw new Error('Network response was not ok');
  }

  const data = await response.json();

  // Se ci sono dettagli utente, setta l'autenticazione
  if (data.details !== null) {
    setAuth({ ...data.details });
  } else {
    // Se i dettagli sono nulli, resetta l'autenticazione
    resetAuth();
  }

  return data;
};

export const logout = async () => {
  const response = await fetch('/logout', {
    method: 'GET',
    credentials: 'same-origin'
  });

  if (!response.ok) {
    throw new Error('Logout failed');
  }

  return response.json();
};

export async function postFeedback(body: FeedbackRequest): Promise<void> {
  const response = await fetch('/feedback', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...body,
      comment: body.comment || ''
    })
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(errorResponse.error || 'Failed to submit feedback');
  }
}
