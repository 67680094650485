import { ReactNode } from 'react';
import { Spinner } from '@fluentui/react';
import { usePermission } from '../../utils/hooks/usePermission';
import { QueryNames } from '../../utils/hooks/usePermission/types';

type PermissionGatewayProps = {
  children: ReactNode;
  permission: QueryNames;
  fallback?: ReactNode;
};

const PermissionGateway = ({
  children,
  permission,
  fallback = null // Default to rendering nothing if the permission check fails
}: PermissionGatewayProps) => {
  const { allowed, isLoading, isPending } = usePermission(permission);

  if (isLoading || isPending) {
    return <Spinner label="Loading..." />;
  }
  if (!isLoading && !isPending && !allowed) {
    return <>{fallback}</>;
  }
  if (allowed) {
    return <>{children}</>;
  }
  return null;
};

export default PermissionGateway;
