import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { devtools } from 'valtio/utils';

import store from './proxies/store';
import { ENV, ENVS } from './utils/constants';
import './index.css';

import RequireAuth from './components/RequireAuth';
import Layout from './pages/layout/Layout';
import Chat from './pages/chat/Chat';

initializeIcons();

const router = createHashRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <Chat />
          </RequireAuth>
        )
      },
      {
        path: 'qa',
        lazy: () => import('./pages/oneshot/OneShot')
      },
      {
        path: '/login',
        lazy: () => import('./pages/login/Login')
      },
      {
        path: '*',
        lazy: () => import('./pages/NoPage')
      }
    ]
  },
  {
    path: '/loginPage',
    lazy: () => import('./pages/loginPage/LoginPage')
  }
]);

if (ENV === ENVS.local || ENV === ENVS.localdev) {
  devtools(store, { name: 'Chatbot', enabled: true });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData
    }
  }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {ENV === ENVS.local || ENV === ENVS.localdev ? (
        <ReactQueryDevtools />
      ) : null}
    </QueryClientProvider>
  </React.StrictMode>
);
