import { proxy } from 'valtio';

import auth from './auth';
import conversation from './conversation';

const store = proxy({
  auth,
  conversation
});

export default store;
