import { Navigate, useLocation } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { Spinner, Stack } from '@fluentui/react';
import { useQuery } from '@tanstack/react-query';
import auth from '../../proxies/auth';
import { getMe } from '../../api';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { authenticated } = useSnapshot(auth);
  const location = useLocation();

  const { isLoading, isError } = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    retry: 0
  });

  if (isLoading) {
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        styles={{ root: { height: '100vh' } }}
      >
        <Spinner label="Loading..." />
      </Stack>
    );
  }

  if (isError || !authenticated) {
    return <Navigate to="/loginPage" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
