import { proxy, subscribe } from 'valtio';

import { AuthType } from './types';

export const init: AuthType = {
  name: null,
  email: null,
  org: null,
  roles: null,
  authenticated: false
};

const storedAuth = localStorage.getItem('auth');
const auth = proxy(storedAuth ? JSON.parse(storedAuth) : init);
subscribe(auth, () => {
  localStorage.setItem('auth', JSON.stringify(auth));
});

export const resetAuth = () => {
  Object.assign(auth, init);
};

export const setAuth = (user: Partial<AuthType>) => {
  auth.authenticated = true;
  auth.name = user.name ?? null;
  auth.email = user.email ?? null;
  auth.org = user.org ?? null;
  auth.roles = user.roles ?? null;
};

export default auth;
