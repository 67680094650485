import React from 'react';
import { Stack, Text, FontIcon, mergeStyleSets } from '@fluentui/react';

type NotAuthorizedProps = {
  title?: string;
  description?: string;
};

const styles = mergeStyleSets({
  container: {
    width: '100%',
    maxWidth: '400px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center'
  },
  icon: {
    fontSize: '32px',
    color: '#d83b01',
    marginBottom: '10px'
  },
  message: {
    marginTop: '10px',
    fontSize: '16px',
    color: '#333'
  }
});

const NotAuthorized: React.FC<NotAuthorizedProps> = ({
  title = 'Access Denied',
  description = 'You do not have permission to view this content. Ask an administrator to grant you correct permissions.'
}) => {
  return (
    <Stack className={styles.container} horizontalAlign="center">
      <FontIcon iconName="LockSolid" className={styles.icon} />
      <Text variant="xLarge">{title}</Text>
      <Text className={styles.message}>{description}</Text>
    </Stack>
  );
};

export default NotAuthorized;
