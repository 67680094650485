import { proxy, subscribe } from 'valtio';

import { ConversationType } from './types';

export const init: ConversationType = {
  conversation_id: null,
  chat_id: null,
  chat_sequence: 0
};

const storedConversation = localStorage.getItem('conversation');
const conversation = proxy(
  storedConversation ? JSON.parse(storedConversation) : init
);
subscribe(conversation, () => {
  localStorage.setItem('conversation', JSON.stringify(conversation));
});

export const resetConversation = () => {
  Object.assign(conversation, init);
};

export default conversation;
