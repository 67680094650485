/* eslint-disable no-unused-vars */
export enum QueryNames {
  IsAnonymous = 'isAnonymous',
  IsAuthenticated = 'isAuthenticated',
  IsAdmin = 'isAdmin'
  // NOTE: other query names can be added here
}

export type PermissionQuery = {
  queryKey: [string, string];
  queryFn: () => Promise<boolean>;
};

export type PermissionQueries = {
  [QueryNames.IsAnonymous]: PermissionQuery;
  [QueryNames.IsAuthenticated]: PermissionQuery;
  [QueryNames.IsAdmin]: PermissionQuery;
  // NOTE: other permission queries can be added here
};

export type UsePermissionResult = {
  isLoading: boolean;
  isError: boolean;
  isPending: boolean;
  allowed: boolean;
};
