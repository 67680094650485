import { useState } from 'react';
import { Stack, TextField } from '@fluentui/react';
import { Send28Filled } from '@fluentui/react-icons';

import styles from './QuestionInput.module.css';

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend
}: Props) => {
  const [question, setQuestion] = useState<string>('');

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion('');
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (!newValue) {
      setQuestion('');
    } else if (newValue.length <= 2000) {
      setQuestion(newValue);
    }
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <Stack
      verticalAlign="space-between"
      className={styles.questionInputContainer}
    >
      <Stack horizontal>
        <TextField
          className={styles.questionInputTextArea}
          placeholder={placeholder}
          multiline
          resizable={false}
          borderless
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onEnterPress}
        />
        <div className={styles.questionInputButtonsContainer}>
          <div
            className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ''}`}
            aria-label="Ask question button"
            onClick={sendQuestion}
          >
            <Send28Filled primaryFill="#0a9ed8" />
          </div>
        </div>
      </Stack>
      <div className={styles.disclaimer}>
        <em>
          Please note: Responses are generated by an AI system and may require
          further verification or refinement based on specific requirements.
        </em>
      </div>
    </Stack>
  );
};
