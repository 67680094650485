import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';

import { Text } from '@fluentui/react';
import { v4 as uuidv4 } from 'uuid';
import auth, { resetAuth } from '../../proxies/auth';
import { SparkleFilled, ArrowExitRegular } from '@fluentui/react-icons';
import styles from './Layout.module.css';
import UserAvatar from '../../components/UserAvatar';
import conversation from '../../proxies/conversation';
import { useSnapshot } from 'valtio';
import { logout } from '../../api';

const Layout = () => {
  const { name, authenticated } = useSnapshot(auth);
  const navigate = useNavigate();

  // Use useMutation to handle the logout process
  const logoutMutation = useMutation({
    mutationFn: logout,
    onSuccess: async () => {
      // Redirect to the login page after successful logout
      navigate('/loginPage?signout=true');
    },
    onError: (error: any) => {
      console.error('Logout failed:', error);
      // Optionally, handle the error (e.g., show a notification)
    }
  });

  useEffect(() => {
    conversation.conversation_id = uuidv4();
    conversation.chat_id = null;
    conversation.chat_sequence = 0;
  }, []);

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  return (
    <div className={styles.layout}>
      <header className={styles.header} role={'banner'}>
        <div className={styles.headerContainer}>
          <Link
            to="#"
            className={styles.headerTitleContainer}
            onClick={(e) => {
              e.preventDefault(); // Prevent the default link behavior
              window.location.reload(); // Reload the current page
            }}
          >
            <SparkleFilled
              fontSize={'64px'}
              primaryFill={'#0a9ed8'}
              aria-hidden="true"
              aria-label="Interagency HR Policies Gen AI"
            />
            <h3 className={styles.headerTitle}>UNify HR</h3>
          </Link>
          {authenticated && (
            <div className={styles.headerRightDiv}>
              <Text className={styles.headerRightText}>{name}</Text>
              <UserAvatar />
              <ArrowExitRegular
                className="logoutButton"
                fontSize={'32px'}
                aria-hidden="true"
                aria-label="exit"
                onClick={handleLogout}
              />
            </div>
          )}
        </div>
      </header>

      <Outlet />
    </div>
  );
};

export default Layout;
