import { Sparkle28Filled } from '@fluentui/react-icons';

export const AnswerIcon = () => {
  return (
    <Sparkle28Filled
      primaryFill={'rgba(115, 118, 225, 1)'}
      aria-hidden="true"
      aria-label="Answer logo"
    />
  );
};
