export const enum Approaches {
  RetrieveThenRead = 'rtr',
  ReadRetrieveRead = 'rrr',
  ReadDecomposeAsk = 'rda',
  ReadRetrieveCompare = 'rrc'
}

export const enum RetrievalMode {
  Hybrid = 'hybrid',
  Vectors = 'vectors',
  Text = 'text'
}

export type AskRequestOverrides = {
  retrievalMode?: RetrievalMode;
  semanticRanker?: boolean;
  semanticCaptions?: boolean;
  excludeCategory?: string;
  top?: number;
  temperature?: number;
  promptTemplate?: string;
  promptTemplatePrefix?: string;
  promptTemplateSuffix?: string;
  suggestFollowupQuestions?: boolean;
  scoringProfile?: boolean;
  // gptApproach?: string;
  organization?: string;
};

export type AskRequest = {
  question: string;
  approach: Approaches;
  overrides?: AskRequestOverrides;
};

export type AskResponse = {
  answer: string;
  thoughts: string | null;
  data_points: string[];
  error?: string;
};

export type ChatTurn = {
  user: string;
  bot?: string;
};

export type ChatRequest = {
  history: ChatTurn[];
  approach: Approaches;
  overrides?: AskRequestOverrides;
};

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  name: string;
  email: string;
  org: string;
}

export interface FeedbackRequest {
  chat_id: string;
  chat_sequence: number;
  feedback: 'thumbs_up' | 'thumbs_down';
  comment?: string;
}
