import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import {
  Stack,
  IconButton,
  TextField,
  MessageBar,
  MessageBarType
} from '@fluentui/react';
import DOMPurify from 'dompurify';

import styles from './Answer.module.css';

import {
  AskResponse,
  FeedbackRequest,
  getCitationFilePath,
  postFeedback
} from '../../api';
import { parseAnswerToHtml } from './AnswerParser';
import SparklingAvatar from '../SparklingAvatar';
import { ArrowDownload16Regular } from '@fluentui/react-icons';
import { useSnapshot } from 'valtio';
import conversation from '../../proxies/conversation';

import { ENV, ENVS } from '../../utils/constants';

enum ThumbSelection {
  Up = 'up',
  Down = 'down',
  None = 'none'
}

enum FeedbackReason {
  ExpectedResponse = 'Expected response',
  Other = 'Other'
}

enum FeedbackResponse {
  Good = 'Good response',
  Bad = 'Bad response'
}

interface Props {
  answer: AskResponse;
  isSelected?: boolean;
  onCitationClicked: (filePath: string) => void;
  onThoughtProcessClicked: () => void;
  onSupportingContentClicked: () => void;
  onFollowupQuestionClicked?: (question: string) => void;
  showFollowupQuestions?: boolean;
}

export const Answer = ({
  answer,
  isSelected,
  onCitationClicked,
  onThoughtProcessClicked,
  onSupportingContentClicked,
  onFollowupQuestionClicked,
  showFollowupQuestions
}: Props) => {
  const { chat_id } = useSnapshot(conversation);
  const { chat_sequence } = useSnapshot(conversation);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackReason, setFeedbackReason] = useState<FeedbackReason | null>(
    null
  );
  const [feedbackText, setFeedbackText] = useState('');
  const [selectedThumb, setSelectedThumb] = useState<ThumbSelection>(
    ThumbSelection.None
  );
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<MessageBarType | undefined>(
    undefined
  );

  const submitFeedback = useMutation({
    mutationFn: postFeedback,
    onSuccess: () => {
      setAlertMessage('Feedback sent successfully');
      setAlertType(MessageBarType.success);
    },
    onError: (error: any) => {
      console.error('Feedback failed:', error);
      setAlertMessage('Failed to send feedback. Please try again.');
      setAlertType(MessageBarType.error);
    }
  });

  const composeComment = (
    reason: FeedbackReason | null,
    text: string
  ): string => {
    if (reason === FeedbackReason.Other && text.trim()) {
      return `${FeedbackReason.Other}: ${text.trim()}`;
    } else if (reason === FeedbackReason.ExpectedResponse && text.trim()) {
      return `${FeedbackReason.ExpectedResponse}: ${text.trim()}`;
    }
    return reason || '';
  };

  const handleThumbsDownClick = () => {
    if (selectedThumb !== ThumbSelection.Down) {
      setSelectedThumb(ThumbSelection.Down);
      setShowFeedback(true);
    }
  };

  const handleThumbsUpFeedback = async () => {
    setSelectedThumb(ThumbSelection.Up);
    await submitFeedback.mutate({
      chat_id: chat_id,
      chat_sequence:chat_sequence,
      feedback: 'thumbs_up',
      comment: ''
    } as FeedbackRequest);
    setShowFeedback(false);
    setFeedbackReason(null);
    setFeedbackText('');
  };

  const handleImmediateFeedback = async (reason: FeedbackReason) => {
    setFeedbackReason(reason);
    await submitFeedback.mutate({
      chat_id: chat_id,
      chat_sequence:chat_sequence,
      feedback: 'thumbs_down',
      comment: reason
    } as FeedbackRequest);
    setShowFeedback(false);
    setFeedbackReason(null);
    setFeedbackText('');
  };

  const handleSendFeedback = async () => {
    const comment = composeComment(feedbackReason, feedbackText);
    await submitFeedback.mutate({
      chat_id: chat_id,
      chat_sequence:chat_sequence,
      feedback: 'thumbs_down',
      comment: comment
    } as FeedbackRequest);
    setShowFeedback(false);
    setFeedbackReason(null);
    setFeedbackText('');
  };

  const parseCitationFilePath = (citation: string): string => {
    // Define a regular expression to match the filename with common extensions
    const regex = /^(.+?\.(?:pdf|docx|txt|xlsx|pptx))/i;
    const match = citation.match(regex);
    const filename = match ? match[1] : citation;

    return filename;
  };

  const parsedAnswer = useMemo(
    () => parseAnswerToHtml(answer.answer, onCitationClicked),
    [answer]
  );

  const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

  return (
    <Stack
      className={`${styles.answerContainer} ${isSelected && styles.selected}`}
      verticalAlign="space-between"
    >
      {alertMessage && (
        <MessageBar
          messageBarType={alertType}
          isMultiline={false}
          onDismiss={() => setAlertMessage(null)}
        >
          {alertMessage}
        </MessageBar>
      )}

      <Stack.Item grow style={{ display: 'flex' }}>
        <div>
          <SparklingAvatar noShadow={false} filled={false} />
        </div>
        <div
          className={styles.answerText}
          dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
        ></div>
        {ENV === ENVS.local || ENV === ENVS.localdev || ENV === ENVS.dev ? (
          <>
            <div>
              <IconButton
                style={{ color: 'black', position: 'inherit' }}
                iconProps={{ iconName: 'Lightbulb' }}
                title="Show thought process"
                ariaLabel="Show thought process"
                onClick={() => onThoughtProcessClicked()}
                disabled={!answer.thoughts}
              />
            </div>
          </>
        ) : null}
        <div>
          <IconButton
            style={{ color: 'black', position: 'inherit' }}
            iconProps={{ iconName: 'ClipboardList' }}
            title="Show supporting content"
            ariaLabel="Show supporting content"
            onClick={() => onSupportingContentClicked()}
            disabled={!answer.data_points.length}
          />
        </div>
      </Stack.Item>

      {!!parsedAnswer.citations.length && (
        <Stack.Item>
          <Stack
            horizontal
            wrap
            tokens={{ childrenGap: 5 }}
            className={styles.citationsContainer}
          >
            <span className={styles.citationLearnMore}>Source documents:</span>
            {parsedAnswer.citations.map((x, i) => {
              const path = parseCitationFilePath(getCitationFilePath(x));
              return (
                <a
                  key={i}
                  className={styles.citation}
                  title={parseCitationFilePath(x)}
                  onClick={() => {
                    onCitationClicked(path);
                  }}
                >
                  {`${++i}. ${parseCitationFilePath(x)}`}
                  <div className={styles.downloadIcon}>
                    <ArrowDownload16Regular
                      style={{ width: '12px', height: '12px' }}
                      primaryFill={'black'}
                      aria-hidden="true"
                    />
                  </div>
                </a>
              );
            })}
          </Stack>
        </Stack.Item>
      )}

      {!!parsedAnswer.followupQuestions.length &&
        showFollowupQuestions &&
        onFollowupQuestionClicked && (
          <Stack.Item>
            <Stack
              horizontal
              wrap
              className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ''}`}
              tokens={{ childrenGap: 6 }}
            >
              <span className={styles.followupQuestionLearnMore}>
                Follow-up questions:
              </span>
              {parsedAnswer.followupQuestions.map((x, i) => {
                return (
                  <a
                    key={i}
                    className={styles.followupQuestion}
                    title={x}
                    onClick={() => onFollowupQuestionClicked(x)}
                  >
                    {`${x}`}
                  </a>
                );
              })}
            </Stack>
          </Stack.Item>
        )}

      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {selectedThumb !== ThumbSelection.Down && (
            <IconButton
              style={{
                color: selectedThumb === ThumbSelection.Up ? 'green' : 'black',
                position: 'inherit'
              }}
              iconProps={{ iconName: 'Like' }}
              title={
                selectedThumb === ThumbSelection.Up
                  ? FeedbackResponse.Good
                  : 'Thumbs up'
              }
              ariaLabel="Thumbs up"
              onClick={handleThumbsUpFeedback}
              disabled={selectedThumb === ThumbSelection.Up}
            />
          )}
          {selectedThumb !== ThumbSelection.Up && (
            <IconButton
              style={{
                color: selectedThumb === ThumbSelection.Down ? 'red' : 'black',
                position: 'inherit'
              }}
              iconProps={{ iconName: 'Dislike' }}
              title={
                selectedThumb === ThumbSelection.Down
                  ? FeedbackResponse.Bad
                  : 'Thumbs down'
              }
              ariaLabel="Thumbs down"
              onClick={handleThumbsDownClick}
              disabled={selectedThumb === ThumbSelection.Down}
            />
          )}
        </Stack>
      </Stack.Item>

      {showFeedback && (
        <Stack.Item>
          <div className={styles.feedbackContainer}>
            <span>Why did you assign this feedback?</span>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <button
                onClick={() =>
                  setFeedbackReason(FeedbackReason.ExpectedResponse)
                }
                className={`${styles.feedbackButton} ${feedbackReason === FeedbackReason.ExpectedResponse ? styles.selectedFeedbackButton : ''}`}
              >
                {FeedbackReason.ExpectedResponse}
              </button>
              <button
                onClick={() => setFeedbackReason(FeedbackReason.Other)}
                className={`${styles.feedbackButton} ${feedbackReason === FeedbackReason.Other ? styles.selectedFeedbackButton : ''}`}
              >
                {FeedbackReason.Other}
              </button>
            </Stack>

            {(feedbackReason === FeedbackReason.Other ||
              feedbackReason === FeedbackReason.ExpectedResponse) && (
              <TextField
                placeholder="Please provide additional feedback"
                value={feedbackText}
                onChange={(e, newValue) => setFeedbackText(newValue || '')}
                multiline
                rows={3}
                className={styles.textArea}
              />
            )}

            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              style={{ marginTop: '10px' }}
            >
              {(feedbackReason === FeedbackReason.Other ||
                feedbackReason === FeedbackReason.ExpectedResponse) && (
                <button
                  onClick={handleSendFeedback}
                  className={styles.sendButton}
                  disabled={!feedbackReason}
                >
                  Send
                </button>
              )}
              <button
                onClick={() => {
                  setSelectedThumb(ThumbSelection.None);
                  setShowFeedback(false);
                  setFeedbackReason(null);
                  setFeedbackText('');
                }}
                className={styles.cancelButton}
              >
                Cancel
              </button>
            </Stack>
          </div>
        </Stack.Item>
      )}
    </Stack>
  );
};
