export const ENV = import.meta.env.VITE_ENV;
export const ENVS = {
  local: 'local',
  localdev: 'localdev',
  dev: 'dev',
  test: 'test',
  prod: 'prod'
};
export enum Roles {
  Admin = 'Admin',
  Viewer = 'Viewer'
}
