import { Example } from './Example';

import styles from './Example.module.css';

export type ExampleModel = {
  text: string;
  value: string;
};

const EXAMPLES: ExampleModel[] = [
  {
    text: 'Can you explain uncertified sick leave?',
    value: 'Can you explain uncertified sick leave?'
  },
  {
    text: 'Can you summarize parental leave for birth parents?',
    value: 'Can you summarize parental leave for birth parents?'
  },
  {
    text: 'I am a fixed-term staff member. Can I request to work 90%?',
    value: 'I am a fixed-term staff member. Can I request to work 90%?'
  }
];

interface Props {
  onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
  return (
    <ul className={styles.examplesNavList}>
      {EXAMPLES.map((x, i) => (
        <li key={i}>
          <Example text={x.text} value={x.value} onClick={onExampleClicked} />
        </li>
      ))}
    </ul>
  );
};
