import styles from './Example.module.css';
import { Icon } from '@fluentui/react/lib/Icon';

interface Props {
  text: string;
  value: string;
  onClick: (value: string) => void;
}

export const Example = ({ text, value, onClick }: Props) => {
  return (
    <div className={styles.example} onClick={() => onClick(value)}>
      <p className={styles.exampleText}>{text}</p>
      <Icon className={styles.exampleIcon} iconName="CRMCustomerInsightsApp" />
    </div>
  );
};
