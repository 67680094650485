import { Person28Regular } from '@fluentui/react-icons';

const UserAvatar = () => {
  return (
    <div
      style={{
        float: 'right',
        height: '36px',
        maxHeight: '36px',
        background: '#0a9ed8',
        paddingTop: '4px',
        paddingLeft: '4px',
        paddingRight: '4px',
        borderRadius: '20px'
      }}
    >
      <Person28Regular
        primaryFill={'white'}
        aria-hidden="true"
        aria-label="Interagency HR Policies Gen AI"
      />
    </div>
  );
};

export default UserAvatar;
