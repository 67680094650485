import { Pivot, PivotItem, PrimaryButton } from '@fluentui/react';
import DOMPurify from 'dompurify';

import styles from './AnalysisPanel.module.css';

import { SupportingContent } from '../SupportingContent';
import { AskResponse } from '../../api';
import { AnalysisPanelTabs } from './AnalysisPanelTabs';

import { ENV, ENVS } from '../../utils/constants';

interface Props {
  className: string;
  activeTab: AnalysisPanelTabs;
  onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
  activeCitation: string | undefined;
  citationHeight: string;
  answer: AskResponse;
  setActiveAnalysisPanelTab: React.Dispatch<
    React.SetStateAction<AnalysisPanelTabs | undefined>
  >;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: 'grey' } };

export const AnalysisPanel = ({
  answer,
  activeTab,
  activeCitation,
  citationHeight,
  className,
  onActiveTabChanged,
  setActiveAnalysisPanelTab
}: Props) => {
  const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
  const isDisabledSupportingContentTab: boolean = !answer.data_points.length;
  const isDisabledCitationTab: boolean = !activeCitation;

  const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);

  return (
    <div
      style={{
        background: '#FFFFFF',
        flex: 1
      }}
    >
      <div style={{ textAlign: 'right' }}>
        <PrimaryButton
          style={{
            position: 'inherit',
            marginRight: '10px',
            marginTop: '10px'
          }}
          onClick={() => setActiveAnalysisPanelTab(undefined)}
        >
          Close
        </PrimaryButton>
      </div>
      <Pivot
        style={{ position: 'inherit' }}
        className={className}
        selectedKey={activeTab}
        onLinkClick={(pivotItem) =>
          pivotItem &&
          onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)
        }
      >
        {ENV === ENVS.local || ENV === ENVS.dev ? (
          <PivotItem
            style={{ position: 'inherit' }}
            itemKey={AnalysisPanelTabs.ThoughtProcessTab}
            headerText="Thought process"
            headerButtonProps={
              isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined
            }
          >
            <div
              className={styles.thoughtProcess}
              dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}
            ></div>
          </PivotItem>
        ) : null}
        <PivotItem
          style={{ position: 'inherit' }}
          itemKey={AnalysisPanelTabs.SupportingContentTab}
          headerText="Supporting content"
          headerButtonProps={
            isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined
          }
        >
          <SupportingContent supportingContent={answer.data_points} />
        </PivotItem>
        {/* <PivotItem
        itemKey={AnalysisPanelTabs.CitationTab}
        headerText="Citation"
        headerButtonProps={
          isDisabledCitationTab ? pivotItemDisabledStyle : undefined
        }
      >
        <iframe
          title="Citation"
          src={activeCitation}
          width="100%"
          height={citationHeight}
        />
      </PivotItem> */}
      </Pivot>
    </div>
  );
};
