import { Sparkle28Regular, Sparkle28Filled } from '@fluentui/react-icons';

const SparklingAvatar = (props: {
  filled: boolean;
  noShadow: boolean;
  extraStyles?: React.CSSProperties | undefined;
}) => {
  const style = {
    background: 'white',
    paddingTop: '4px',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '20px',
    boxShadow:
      '0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)'
  };

  return (
    <div
      style={
        props.noShadow
          ? { ...style, ...{ boxShadow: 'none' }, ...props.extraStyles }
          : { ...style, ...props.extraStyles }
      }
    >
      {props.filled ? (
        <Sparkle28Filled
          style={props.extraStyles}
          primaryFill={'#0a9ed8'}
          aria-hidden="true"
          aria-label="Interagency HR Policies Gen AI"
        />
      ) : (
        <Sparkle28Regular
          primaryFill={'#0a9ed8'}
          aria-hidden="true"
          aria-label="Interagency HR Policies Gen AI"
        />
      )}
    </div>
  );
};

export default SparklingAvatar;
