import { useQuery } from '@tanstack/react-query';
import { snapshot, useSnapshot } from 'valtio';
import { Roles } from '../../constants';

import auth from '../../../proxies/auth';

import { PermissionQueries, QueryNames, UsePermissionResult } from './types';

const isAnonymous = async (): Promise<boolean> => {
  return true;
};

const isAuthenticated = async (): Promise<boolean> => {
  const { authenticated } = snapshot(auth);
  if (authenticated) {
    return true;
  }
  return false;
};

const isAdmin = async (): Promise<boolean> => {
  const { roles } = snapshot(auth);
  return roles && roles.includes(Roles.Admin);
};

const permissionQueries: PermissionQueries = {
  [QueryNames.IsAnonymous]: {
    queryKey: ['permissions', QueryNames.IsAnonymous],
    queryFn: isAnonymous
  },
  [QueryNames.IsAuthenticated]: {
    queryKey: ['permissions', QueryNames.IsAuthenticated],
    queryFn: isAuthenticated
  },
  [QueryNames.IsAdmin]: {
    queryKey: ['permissions', QueryNames.IsAdmin],
    queryFn: isAdmin
  }
  // NOTE: other permission queries can be added here
};

export const usePermission = (
  queryName: QueryNames = QueryNames.IsAnonymous
): UsePermissionResult => {
  const { ...props } = useSnapshot(auth);
  const query = permissionQueries[queryName];
  const { isLoading, isError, data, isPending } = useQuery({
    queryKey: [...query.queryKey, props],
    queryFn: query.queryFn
  });

  return {
    isLoading,
    isPending,
    isError,
    allowed: data ?? false
  };
};
