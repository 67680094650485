import { useRef, useState, useEffect } from 'react';
import {
  ChoiceGroup,
  IChoiceGroupOption,
  Panel,
  PanelType,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IChoiceGroupStyles
} from '@fluentui/react';
import { useSnapshot } from 'valtio';

import { Callout, MessageBar, MessageBarType } from '@fluentui/react';

import styles from './Chat.module.css';

import {
  chatApi,
  RetrievalMode,
  Approaches,
  AskResponse,
  ChatRequest,
  ChatTurn
} from '../../api';
import { Answer, AnswerError, AnswerLoading } from '../../components/Answer';
import { QuestionInput } from '../../components/QuestionInput';
import { ExampleList } from '../../components/Example';
import { UserChatMessage } from '../../components/UserChatMessage';
import {
  AnalysisPanel,
  AnalysisPanelTabs
} from '../../components/AnalysisPanel';
import { ClearChatButton } from '../../components/ClearChatButton';

import auth from '../../proxies/auth';
import conversation from '../../proxies/conversation';
import PermissionGateway from '../../components/PermissionGateway';
import { QueryNames } from '../../utils/hooks/usePermission/types';
import NotAuthorized from '../../components/NotAuthorized';
import { ENV, ENVS } from '../../utils/constants';

const styles2: Partial<IChoiceGroupStyles> = {
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.0rem'
  }
};

const dropdownOptions: IDropdownOption[] = [
  { key: 'IAEA', text: 'IAEA' },
  { key: 'ICAO', text: 'ICAO' },
  { key: 'IOM', text: 'IOM' },
  { key: 'ITU', text: 'ITU' },
  { key: 'UNWOMEN', text: 'UN Women' },
  { key: 'UNAIDS', text: 'UNAIDS' },
  { key: 'UNDP', text: 'UNDP' },
  { key: 'UNESCO', text: 'UNESCO' },
  { key: 'UNHCR', text: 'UNHCR' },
  { key: 'UNICEF', text: 'UNICEF' },
  { key: 'UNOPS', text: 'UNOPS' },
  { key: 'UNRWA', text: 'UNRWA' },
  { key: 'WFP', text: 'WFP' }
];

const filterDropDownOptions: IDropdownOption[] = [
  { key: '', text: 'No organization' },
  ...dropdownOptions
];

const Chat = () => {
  const [approach, setApproach] = useState<Approaches>(
    Approaches.ReadRetrieveRead
  );

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [targetElement, setTargetElement] = useState(null);

  const { org } = useSnapshot(auth);
  const orgExistsInOptions = dropdownOptions.some(
    (option) => option.key === org
  );
  const [filterSelectedKey, setFilterSelectedKey] = useState<string>(
    orgExistsInOptions ? org : ''
  );

  const [compareSelectedKeys, setCompareSelectedKeys] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  const [citationURL, setCitationURL] = useState('');
  const [isPDFPanelOpen, setIsPDFPanelOpen] = useState(false);

  const promptTemplate = '';
  const retrieveCount = 5;
  const retrievalMode = RetrievalMode.Hybrid;
  const useSemanticRanker = true;
  const useSemanticCaptions = false;
  const excludeCategory = '';
  const useSuggestFollowupQuestions = false;

  const useScoringProfile = false;

  const lastQuestionRef = useRef<string>('');
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const [activeCitation, setActiveCitation] = useState<string>();
  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<
    AnalysisPanelTabs | undefined
  >(undefined);

  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [answers, setAnswers] = useState<
    [user: string, response: AskResponse][]
  >([]);

  const toggleCallout = (event: any) => {
    setTargetElement(event.currentTarget);
    setIsCalloutVisible(!isCalloutVisible);
  };

  /**
   * Handles the change event of the filter dropdown.
   *
   * @param {React.FormEvent<HTMLDivElement>} event - The event object.
   * @param {IDropdownOption} item - The dropdown option.
   * @return void
   */
  const onFilterDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption<any> | undefined
  ): void => {
    if (typeof option !== 'undefined') {
      if (option) {
        setFilterSelectedKey(option.key as string);
      }
    }
  };

  const onCompareDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption<any> | undefined
  ): void => {
    let newCompareSelectedKeys: string[];
    if (typeof option !== 'undefined') {
      if (option.selected) {
        newCompareSelectedKeys = [...compareSelectedKeys, option.key as string];
      } else {
        newCompareSelectedKeys = compareSelectedKeys.filter(
          (key) => key !== option.key
        );
      }

      if (newCompareSelectedKeys.length > 3) {
        setMessage('You can select from 2 to 3 organizations to compare');
        setTimeout(() => {
          setMessage('');
        }, 2000);
      } else {
        setCompareSelectedKeys(newCompareSelectedKeys);
        setMessage('');
      }
    }
  };

  const getOrganizations = () => {
    if (approach === Approaches.ReadRetrieveRead) {
      if (filterSelectedKey === '') {
        return undefined;
      }
      return filterSelectedKey;
    }
    if (approach === Approaches.ReadRetrieveCompare) {
      if (compareSelectedKeys.length === 0) {
        return undefined;
      }
      return compareSelectedKeys.join(',');
    }
    return undefined;
  };

  const makeApiRequest = async (question: string) => {
    if (
      approach === Approaches.ReadRetrieveCompare &&
      (compareSelectedKeys.length < 2 || compareSelectedKeys.length > 3)
    ) {
      setMessage('You can select from 2 to 3 organizations to compare');
      setTimeout(() => {
        setMessage('');
      }, 2000);
      return Promise.resolve();
    }
    lastQuestionRef.current = question;

    error && setError(undefined);
    setIsLoading(true);
    setActiveCitation(undefined);
    setCitationURL('');
    setActiveAnalysisPanelTab(undefined);

    try {
      const history: ChatTurn[] = answers.map((a) => ({
        user: a[0],
        bot: a[1].answer
      }));
      const request: ChatRequest = {
        history: [...history, { user: question, bot: undefined }],
        approach,
        overrides: {
          promptTemplate:
            promptTemplate.length === 0 ? undefined : promptTemplate,
          excludeCategory:
            excludeCategory.length === 0 ? undefined : excludeCategory,
          organization: getOrganizations(),
          top: retrieveCount,
          retrievalMode: retrievalMode,
          semanticRanker: useSemanticRanker,
          semanticCaptions: useSemanticCaptions,
          suggestFollowupQuestions: useSuggestFollowupQuestions,
          scoringProfile: useScoringProfile
        }
      };
      const result = await chatApi(request);
      setAnswers([...answers, [question, result]]);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    lastQuestionRef.current = '';
    error && setError(undefined);
    setActiveCitation(undefined);
    setCitationURL('');
    setActiveAnalysisPanelTab(undefined);
    setAnswers([]);
    conversation.chat_id = null;
    conversation.chat_sequence = 0;
  };

  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'smooth' }),
    [isLoading]
  );

  const onApproachChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setApproach((option?.key as Approaches) || Approaches.ReadRetrieveRead);
  };

  const onExampleClicked = (example: string) => {
    makeApiRequest(example);
  };

  const onShowCitation = (citation: string, index: number) => {
    setCitationURL(citation);
    setIsPDFPanelOpen(true);
  };

  const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
    if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveAnalysisPanelTab(tab);
    }

    setSelectedAnswer(index);
  };

  const approaches: IChoiceGroupOption[] = [
    {
      key: Approaches.ReadRetrieveRead,
      text: 'Filter'
    },
    {
      key: Approaches.ReadRetrieveCompare,
      text: 'Compare'
    }
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.chatRoot}>
          <div className={styles.chatContainer}>
            {!lastQuestionRef.current ? (
              <div className={styles.chatEmptyState}>
                <p className={styles.chatEmptyStateTitle}>
                  Hello <span style={{ color: '#0a9ed8' }}>{auth.name}</span>
                  <br />
                  How can I help you?
                </p>

                <ExampleList onExampleClicked={onExampleClicked} />
              </div>
            ) : (
              <div className={styles.chatMessageStream}>
                {answers.map((answer, index) => (
                  <div key={index}>
                    <UserChatMessage message={answer[0]} />
                    <div className={styles.chatMessageGpt}>
                      <Answer
                        key={index}
                        answer={answer[1]}
                        isSelected={
                          selectedAnswer === index &&
                          activeAnalysisPanelTab !== undefined
                        }
                        onCitationClicked={(c) => onShowCitation(c, index)}
                        onThoughtProcessClicked={() =>
                          onToggleTab(
                            AnalysisPanelTabs.ThoughtProcessTab,
                            index
                          )
                        }
                        onSupportingContentClicked={() =>
                          onToggleTab(
                            AnalysisPanelTabs.SupportingContentTab,
                            index
                          )
                        }
                        onFollowupQuestionClicked={(q) => makeApiRequest(q)}
                        showFollowupQuestions={
                          useSuggestFollowupQuestions &&
                          answers.length - 1 === index
                        }
                      />
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <>
                    <UserChatMessage message={lastQuestionRef.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                      <AnswerLoading />
                    </div>
                  </>
                )}
                {error ? (
                  <>
                    <UserChatMessage message={lastQuestionRef.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                      <AnswerError
                        error={error.toString()}
                        onRetry={() => makeApiRequest(lastQuestionRef.current)}
                      />
                    </div>
                  </>
                ) : null}
                <div ref={chatMessageStreamEnd} />
              </div>
            )}
            <div className={styles.chatInput}>
              <div className={styles.questionToolbar}>
                <div className={styles.questionToolbarConfiguration}>
                  <img
                    src="assets/images/icon_settings.png"
                    alt="Settings"
                    className={styles.approachIcon}
                    onClick={toggleCallout}
                  />{' '}
                  <div className={styles.approachLabel} onClick={toggleCallout}>
                    Approach
                  </div>
                  {isCalloutVisible && (
                    <Callout
                      target={targetElement}
                      onDismiss={() => setIsCalloutVisible(false)}
                      setInitialFocus
                      directionalHint={0} // Top center
                      className={styles.callout}
                    >
                      <div style={{ padding: '20px', width: '450px' }}>
                        <ChoiceGroup
                          className={styles.chatSettingsSeparator}
                          label="Approach"
                          options={approaches}
                          defaultSelectedKey={approach}
                          onChange={onApproachChange}
                          styles={styles2}
                        />
                        {approach === Approaches.ReadRetrieveRead && (
                          <Dropdown
                            placeholder="Select options"
                            label="Select organizations to filter"
                            options={filterDropDownOptions}
                            selectedKey={filterSelectedKey}
                            onChange={onFilterDropdownChange}
                          />
                        )}
                        {approach === Approaches.ReadRetrieveCompare && (
                          <Dropdown
                            placeholder="Select options"
                            label="Select organizations to compare"
                            multiSelect
                            options={dropdownOptions}
                            selectedKeys={compareSelectedKeys}
                            onChange={onCompareDropdownChange}
                          />
                        )}
                        <div className={styles.disclaimer}>
                          <em>
                            The Filter and Compare features continue to undergo
                            accuracy work. Note that using the Compare feature
                            may be slow (up to 1 minute) as we make further
                            improvements.
                          </em>
                        </div>
                      </div>
                    </Callout>
                  )}
                </div>
                <div className={styles.questionToolbarClearButton}>
                  <ClearChatButton
                    className={styles.commandButton}
                    onClick={clearChat}
                    disabled={!lastQuestionRef.current || isLoading}
                  />
                </div>
              </div>
              <QuestionInput
                clearOnSend
                placeholder="Type a new question (e.g. Who is eligible for After-Service Medical Coverage (ASMC)?)"
                disabled={isLoading}
                onSend={(question) => makeApiRequest(question)}
              />
            </div>
          </div>
          {answers.length > 0 && activeAnalysisPanelTab && (
            <AnalysisPanel
              className={styles.chatAnalysisPanel}
              activeCitation={activeCitation}
              onActiveTabChanged={(x) => onToggleTab(x, selectedAnswer)}
              citationHeight="810px"
              answer={answers[selectedAnswer][1]}
              activeTab={activeAnalysisPanelTab}
              setActiveAnalysisPanelTab={setActiveAnalysisPanelTab}
            />
          )}
        </div>
        {message && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              width: '50%',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              onDismiss={() => {
                setMessage('');
              }}
            >
              {message}
            </MessageBar>
          </div>
        )}
      </div>
      <Panel
        styles={{ root: styles.panel }}
        headerText="PDF document"
        type={PanelType.custom}
        customWidth="880px"
        isOpen={isPDFPanelOpen}
        isBlocking={false}
        onDismiss={() => {
          setIsPDFPanelOpen(false);
          setCitationURL('');
        }}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={() => (
          <>
            <DefaultButton onClick={() => setIsPDFPanelOpen(false)}>
              Close
            </DefaultButton>
          </>
        )}
        isFooterAtBottom={true}
      >
        <iframe
          src={citationURL}
          className={styles.iframe}
          title="PDF Viewer"
        ></iframe>
      </Panel>
    </>
  );
};

const ChatWrapper = () => {
  return (
    <PermissionGateway
      permission={QueryNames.IsAdmin}
      fallback={
        <NotAuthorized description="You do not have permission to view this content. Ask an administrator to grant you Admin role." />
      }
    >
      <Chat />
    </PermissionGateway>
  );
};

export default ChatWrapper;
